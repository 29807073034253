import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline'

export const useMessageModal = (config: {
  title?: string
  message?: string,
  buttonText?: string,
  success?: boolean,
  onClose?: () => void
} = {}) => {
  const { Modal, show, hide } = useModal({
    closeOnClickOutside: true
  })

  const MessageModal = defineComponent({
    name: 'MessageModal',
    setup: (_, { slots }) => {
      return () => {
        const icon = config.success === false
          ? h(XCircleIcon, { class: 'h-12 text-red-600' })
          : h(CheckCircleIcon, { class: 'h-12 text-green-600' })
        const title = h('div', { class: 'text-2xl font-bold' }, config?.title || 'لمونو')
        const message = h('div', { class: 'font-semibold' }, config?.message || 'با موفقیت انجام شد')
        const button = h('button', { class: 'button-sm', onClick: hide }, config?.buttonText || 'متوجه شدم')

        const layout = h('div', {
          class: 'grid gap-4 justify-items-center p-6 min-w-72',
        }, [icon, title, message, button])

        return h(Modal, {}, () => layout)
      }
    }
  })

  return { MessageModal, show, hide }
}
